export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'username',
    sortable: false,
    sortField: 'username',
    label: 'field.username',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'phone',
    sortable: false,
    sortField: 'phone',
    label: 'field.phone_number',
  },
  {
    key: 'amount',
    sortable: false,
    sortField: '',
    label: 'field.amount',
    tdClass: 'text-right',
  },
  {
    key: 'imageUrl',
    sortable: false,
    sortField: '',
    label: 'field.screenshot',
    tdClass: 'text-center',
  },
  {
    key: 'note',
    sortable: false,
    sortField: '',
    label: 'field.note',
    tdClass: 'text-center',
  },
  {
    key: 'createdAt',
    sortable: false,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'status',
    sortable: false,
    sortField: 'status',
    label: 'field.status',
  },
  {
    key: 'verifier',
    label: 'field.verifier',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
